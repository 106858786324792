<template>
    <layout
        :activeIndex="activeIndex"
        :activeModel.sync="activeModel"
        :topBarItems="topBarItems"
        @setIndex="setIndex"
    >
        <template #left-0>

        </template>
        <template #left-1></template>
        <template #right-0>
            <panel-group
                v-if="component_key === 'panel-group'"
                :group-uid="group_uid"
                :show-title="false"
                style="margin-top: -1rem"
            ></panel-group>
            <component :is="component_key" v-else></component>
            <b-modal
                v-model="energy_alarm_modal.show"
                title="能耗异常提醒"
                centered
                hide-footer
                size="lg"
            >
                <div class="w-100" style="height: 60vh">
                    <b-tabs>
                        <b-tab
                            v-for="(item, index) in energy_alarm_modal.tabs"
                            :key="'sub_system_tabs_' + index"
                            :title="item.title"
                        >
                            <div
                                :id="
                                    'jsf35-energy-alarm-scroll-notice_' + index
                                "
                                style="height: 55vh; overflow-y: scroll"
                                @mouseenter="
                                    scroll_stop(
                                        '#jsf35-energy-alarm-scroll-notice_' +
                                            index
                                    )
                                "
                                @mouseleave="
                                    scroll_active(
                                        '#jsf35-energy-alarm-scroll-notice_' +
                                            index
                                    )
                                "
                            >
                                <app-timeline
                                    v-if="
                                        item['list'] && item['list'].length > 0
                                    "
                                    class="mt-1 jsf-timeline"
                                >
                                    <app-timeline-item
                                        v-for="(
                                            alarmItem, alarmItemIndex
                                        ) in item['list']"
                                        :key="'alarm_item_' + alarmItemIndex"
                                        :subtitle="alarmItem['text']"
                                        :time="alarmItem['time']"
                                        :title="alarmItem['title']"
                                        class="pb-1 text-shadow"
                                        variant="warning"
                                        style="cursor: pointer"
                                    />
                                </app-timeline>
                                <h6 v-else class="text-center mt-5">
                                    暂无报警信息
                                </h6>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </b-modal>
        </template>
        <template #right-1></template>
    </layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { onMounted, reactive, ref, toRefs } from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import PanelGroup from "@/views/panel-group";
import store from "@/store";
import AppTimeline from "@core/components/app-timeline/AppTimeline";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem";
import { scroll_active, scroll_stop } from "@/libs/utils/autoScroll";
import { getEnergyDataList, getEnergyQuotas } from "@/api/jsf35";
import { momentJs } from "@/libs/utils/moment";

export default {
    name: "JsfEnergyManagement",
    components: {
        Layout,
        LeftMenu,
        PanelGroup,
        AppTimeline,
        AppTimelineItem,
        history: () => import("./components/history"),
        totalEnergy: () => import("./components/charts"),
        spaceElectricity: () => import("./components/charts"),
        spaceWater: () => import("./components/charts"),
        mainEnergy: () => import("./components/charts"),
        trendElec: () => import("./components/trend-elec"),
        trendWater: () => import("./components/trend-water"),
        rankingElec: () => import("./components/ranking-elec"),
        rankingElecPosition: () => import("./components/ranking-elec-position"),
        calibrationElec: () => import("./components/calibration-elec"),
        calibrationWater: () => import("./components/calibration-water"),
        quotasElec: () => import("./components/quotas-elec"),
        quotasWater: () => import("./components/quotas-water"),
    },
    setup() {
        // 框架相关参数
        const layoutParams = reactive({
            activeIndex: 0,
            activeModel: "",
            topBarItems: [
                { title: "主页" },
                // {title: "历史能耗"}
            ],
        });
        //左侧菜单相关参数
        const leftMenuParams = reactive({
            //树形菜单配置：
            tree: [
                {
                    title: "总能耗统计",
                    children: [
                        {
                            title: "能耗总览",
                            icon: "GridIcon",
                            component_key: "panel-group",
                            group_uid: "aef8ad2d",
                        },
                        {
                            title: "总电耗统计",
                            icon: "ZapIcon",
                            component_key: "panel-group",
                            group_uid: "fe22b5ba",
                        },
                        {
                            title: "总水耗统计",
                            icon: "DropletIcon",
                            component_key: "panel-group",
                            group_uid: "7a49a240",
                        },
                    ],
                },
                {
                    title: "空间用电统计",
                    children: [
                        {
                            title: "分区总能耗",
                            icon: "LayoutIcon",
                            component_key: "panel-group",
                            group_uid: "7797ec8d",
                        },
                        /* {
                title: "分项能耗",
                icon: "DropletIcon",
                component_key: "panel-group",
                group_uid: "07aeced5",
            }, */
                        {
                            title: "办公区能耗",
                            icon: "CoffeeIcon",
                            component_key: "panel-group",
                            group_uid: "292dbc98",
                        },
                        {
                            title: "商业区能耗",
                            icon: "ShoppingCartIcon",
                            component_key: "panel-group",
                            group_uid: "65165f1d",
                        },
                        {
                            title: "停车场能耗",
                            icon: "TruckIcon",
                            component_key: "panel-group",
                            group_uid: "3c6f7910",
                        },
                        {
                            title: "后勤区能耗",
                            icon: "ToolIcon",
                            component_key: "panel-group",
                            group_uid: "73e7d528",
                        },
                        {
                            title: "设备机房能耗",
                            icon: "ServerIcon",
                            component_key: "panel-group",
                            group_uid: "5f4ac36e",
                        },
                    ],
                },
                {
                    title: "空间用水统计",
                    children: [
                        {
                            title: "高区生活水",
                            component_key: "panel-group",
                            icon: "CornerLeftUpIcon",
                            group_uid: "381d3f80",
                        },
                        {
                            title: "低区生活水",
                            component_key: "panel-group",
                            icon: "CornerRightDownIcon",
                            group_uid: "0f9674f0",
                        },
                        {
                            title: "中水",
                            component_key: "panel-group",
                            icon: "RefreshCcwIcon",
                            group_uid: "65066881",
                        },
                    ],
                },
                {
                    title: "主要能耗系统统计",
                    children: [
                        {
                            title: "主要能耗系统统计_1",
                            component_key: "panel-group",
                            icon: "AirplayIcon",
                            group_uid: "2f75126e",
                        },
                        {
                            title: "主要能耗系统统计_2",
                            component_key: "panel-group",
                            icon: "AnchorIcon",
                            group_uid: "621ef4d9",
                        },
                        {
                            title: "主要能耗系统统计_3",
                            component_key: "panel-group",
                            icon: "ApertureIcon",
                            group_uid: "aa6d2850",
                        },
                        {
                            title: "主要能耗系统统计_4",
                            component_key: "panel-group",
                            icon: "CastIcon",
                            group_uid: "36b787a6",
                        },
                        {
                            title: "主要能耗系统统计_5",
                            icon: "DatabaseIcon",
                            component_key: "panel-group",
                            group_uid: "52b0816e",
                        },
                        {
                            title: "主要能耗系统统计_6",
                            component_key: "panel-group",
                            icon: "CpuIcon",
                            group_uid: "c4e7704d",
                        },
                    ],
                },
                {
                    title: "能源趋势分析",
                    children: [
                        {
                            title: "用电趋势分析",
                            component_key: "trendElec",
                            icon: "ZapIcon",
                        },
                        {
                            title: "用水趋势分析",
                            component_key: "trendWater",
                            icon: "DropletIcon",
                        },
                    ],
                },
                {
                    title: "能源排名对比",
                    children: [
                        {
                            title: "用电各分项对比",
                            component_key: "rankingElec",
                            icon: "ZapIcon",
                        },
                        {
                            title: "用电各位置对比",
                            component_key: "rankingElecPosition",
                            icon: "DropletIcon",
                        },
                    ],
                },
                {
                    title: "能源校核",
                    children: [
                        {
                            title: "用电校核",
                            component_key: "calibrationElec",
                            icon: "ZapIcon",
                            group_uid: "7cde8993",
                        },
                        {
                            title: "用水校核",
                            component_key: "calibrationWater",
                            icon: "DropletIcon",
                        },
                    ],
                },
                {
                    title: "能源定额",
                    children: [
                        {
                            title: "用电定额",
                            component_key: "quotasElec",
                            icon: "ZapIcon",
                            group_uid: "438dd8a6",
                        },
                        {
                            title: "用水定额",
                            component_key: "quotasWater",
                            icon: "DropletIcon",
                        },
                    ],
                },
            ],
        });

        // 左侧菜单选中事件
        const leftMenuSelect = (event) => {
            console.log("leftMenuSelectEvent", event);
            data.group_uid = event.group_uid;
            data.component_key = event.component_key;
            // activeModel.value = event.model
        };
        const setIndex = (e) => {
            layoutParams.activeIndex = e;
        };
        const data = reactive({
            num: null,
            component_key: "panel-group",
            /* component_key: "totalEnergy", */
            group_uid: "aef8ad2d",
            energy_alarm_modal: {
                show: true,
                tabs: [
                    {
                        title: "用电超额提醒",
                        list: [],
                    },
                    {
                        title: "用水超额提醒",
                        list: [],
                    },
                ],
            },
        });

        //提交PanelGroup
        const panelGroup = ref({
            name: "",
            uid: "",
        });
        const panelGroupSubmit = () => {
            store
                .dispatch("panel-group/addPanelGroup", {
                    group_name: panelGroup.value.name,
                })
                .then((response) => {
                    // console.log(response.data);
                    panelGroup.value.uid = response.data.group_uuid;
                });
        };

        const getFilters = () => {
            store
                .dispatch("jsf35/fetchEnergyFilters", { project_id: 72 })
                .then((res) => {
                    // console.log("filters", res.data);
                    store.commit("jsf35/setEnergyFilters", res.data);
                });
        };

        //计算每月天数
        const count_month_days = () => {
            let year = momentJs().format("YYYY");
            let month_days = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            let days_arr = { 水: {}, 电: {} };
            for (let i = 0; i < 12; i++) {
                const month = i + 1;
                const days = momentJs(
                    year + "-" + month,
                    "YYYY-M"
                ).daysInMonth();
                const month_str =
                    year + "-" + (month < 10 ? "0" + month : month) + "-";
                for (let d = 1; d <= days; d++) {
                    let day_str = month_str + (d < 10 ? "0" + d : d);
                    days_arr["水"][day_str] = {
                        time: day_str,
                        value: 0,
                        egname: "水",
                    };
                    days_arr["电"][day_str] = {
                        time: day_str,
                        value: 0,
                        egname: "电",
                    };
                }
                month_days[i] = days;
            }
            return { month_days, days_arr };
        };

        //获取能耗超额提醒
        const getEnergyAlarmData = async () => {
            let date = momentJs();
            let params = {
                project_id: 72,
                time:
                    "(" +
                    date.startOf("year").format("YYYY-MM-DD HH:mm:ss") +
                    "," +
                    date.endOf("year").format("YYYY-MM-DD HH:mm:ss") +
                    ")",
            };
            const { month_days, days_arr } = count_month_days();
            let data_days_arr = days_arr;
            //各月平均每天的定额
            let month_day_avg_quotas = {
                水: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                电: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            };
            await getEnergyQuotas({ project_id: 72, energy_type: 0 }).then(
                (res) => {
                    let month_value = res.data.month_value.split(",");
                    month_value.map((quotas, index) => {
                        month_day_avg_quotas["电"][index] =
                            Math.round(
                                (parseFloat(quotas) / month_days[index]) * 100
                            ) / 100;
                    });
                }
            );
            await getEnergyQuotas({ project_id: 72, energy_type: 1 }).then(
                (res) => {
                    let month_value = res.data.month_value.split(",");
                    month_value.map((quotas, index) => {
                        month_day_avg_quotas["水"][index] =
                            Math.round(
                                (parseFloat(quotas) / month_days[index]) * 100
                            ) / 100;
                    });
                }
            );
            await getEnergyDataList({ ...params, table: "d" }).then((res) => {
                res.data.list.map((item) => {
                    const value = item.value || 0;
                    const time = item.time.split(" ")[0];
                    const egname = item.egname === "水" ? "水" : "电";
                    if (!data_days_arr[egname][time]) {
                        data_days_arr[egname][time] = {
                            value: 0,
                            time: time,
                            egname: egname,
                        };
                    }
                    data_days_arr[egname][time]["value"] =
                        data_days_arr[egname][time]["value"] + value;
                });
            });
            let alarm_tabs = [
                { title: "用电超额提醒", list: [] },
                { title: "用水超额提醒", list: [] },
            ];
            for (let egname in data_days_arr) {
                for (let day in data_days_arr[egname]) {
                    let monthIndex = momentJs(day).month();
                    let value =
                        Math.round(data_days_arr[egname][day].value * 100) /
                        100;
                    let quotas = month_day_avg_quotas[egname][monthIndex];
                    let diff = Math.round((value - quotas) * 100) / 100;
                    if (quotas !== 0 && value >= quotas) {
                        console.log("alarm", data_days_arr[egname][day]);
                        alarm_tabs[egname === "电" ? 0 : 1]["list"].push({
                            time: day,
                            title: "超额提醒",
                            text:
                                "用" +
                                egname +
                                "定额: " +
                                quotas +
                                " kWh;  实际用" +
                                egname +
                                ": " +
                                value +
                                " kWh;  超出定额: " +
                                diff +
                                " kWh",
                        });
                    }
                }
            }
            data.energy_alarm_modal.tabs = Object.assign({}, alarm_tabs);
        };

        onMounted(() => {
            getFilters();
            getEnergyAlarmData();
        });

        return {
            ...toRefs(layoutParams),
            ...toRefs(leftMenuParams),
            leftMenuSelect,
            setIndex,
            ...toRefs(data),
            panelGroup,
            panelGroupSubmit,
            scroll_active,
            scroll_stop,
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
