var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{attrs:{"activeIndex":_vm.activeIndex,"activeModel":_vm.activeModel,"topBarItems":_vm.topBarItems},on:{"update:activeModel":function($event){_vm.activeModel=$event},"update:active-model":function($event){_vm.activeModel=$event},"setIndex":_vm.setIndex},scopedSlots:_vm._u([{key:"left-0",fn:function(){return undefined},proxy:true},{key:"left-1",fn:function(){return undefined},proxy:true},{key:"right-0",fn:function(){return [(_vm.component_key === 'panel-group')?_c('panel-group',{staticStyle:{"margin-top":"-1rem"},attrs:{"group-uid":_vm.group_uid,"show-title":false}}):_c(_vm.component_key,{tag:"component"}),_c('b-modal',{attrs:{"title":"能耗异常提醒","centered":"","hide-footer":"","size":"lg"},model:{value:(_vm.energy_alarm_modal.show),callback:function ($$v) {_vm.$set(_vm.energy_alarm_modal, "show", $$v)},expression:"energy_alarm_modal.show"}},[_c('div',{staticClass:"w-100",staticStyle:{"height":"60vh"}},[_c('b-tabs',_vm._l((_vm.energy_alarm_modal.tabs),function(item,index){return _c('b-tab',{key:'sub_system_tabs_' + index,attrs:{"title":item.title}},[_c('div',{staticStyle:{"height":"55vh","overflow-y":"scroll"},attrs:{"id":'jsf35-energy-alarm-scroll-notice_' + index},on:{"mouseenter":function($event){return _vm.scroll_stop(
                                    '#jsf35-energy-alarm-scroll-notice_' +
                                        index
                                )},"mouseleave":function($event){return _vm.scroll_active(
                                    '#jsf35-energy-alarm-scroll-notice_' +
                                        index
                                )}}},[(
                                    item['list'] && item['list'].length > 0
                                )?_c('app-timeline',{staticClass:"mt-1 jsf-timeline"},_vm._l((item['list']),function(alarmItem,alarmItemIndex){return _c('app-timeline-item',{key:'alarm_item_' + alarmItemIndex,staticClass:"pb-1 text-shadow",staticStyle:{"cursor":"pointer"},attrs:{"subtitle":alarmItem['text'],"time":alarmItem['time'],"title":alarmItem['title'],"variant":"warning"}})}),1):_c('h6',{staticClass:"text-center mt-5"},[_vm._v(" 暂无报警信息 ")])],1)])}),1)],1)])]},proxy:true},{key:"right-1",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }